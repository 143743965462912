import React from "react"

function Lifestyle() {
  return (
    <div
      className="px-[4%] lg:px-[8%] mx-auto bg-zinc-900
    
    bg-[linear-gradient(to_right,rgba(24,24,27,0.9),rgba(24,24,27,0.5)),url('/src/img/wellness.jpg')]
    bg-cover bg-center
    py-20"
    >
      <p className="text-3xl text-left font-bold text-white py-10 uppercase lg:text-5xl">
        Maximize <span className="text-orange-500">Lifestyle & Wellness</span>
        <br />
        For Peak <span className="text-orange-500">Performance</span>
      </p>
      <hr className="w-2/12 h-2 bg-gradient-to-r from-red-700 to-orange-500 border-none" />

      <p className="text-white text-base lg:w-8/12 py-20">
        At Phoenix we are dedicated to the all round development of every
        athlete on our program. we hope to facilitate an environment that
        fosters growth and development of our youths into future change agents,
        thought leaders and flag bearers.
        <br />
        <br />
        Considering our approach is holistic , our wellness department gives
        athletes on our program access to mental conditioning ,nutrition ,
        physiotherapy and many more elements.
      </p>
    </div>
  )
}

export default Lifestyle
