import React from "react"

import Footer from "../components/footer"

function TermsAndConditions() {
  return (
    <div className=''>
      <div className='w-11/12 lg:w-10/12 py-10 lg:w-4/5 mx-auto'>
        <h1 className='text-3xl lg:text-5xl text-left font-bold text-orange-500 uppercase py-10'>
          Terms And Conditions
        </h1>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          1. Acceptance of Terms
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          By signing up on the Phoenix Sports Academy website, you agree to
          comply with and be bound by the following terms and conditions. If you
          do not agree with any of these terms, please do not use our services.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          2. Registration and Account Security
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          You must provide accurate and complete information during the sign-up
          process, including your full name, valid email address, phone number,
          and age. You are responsible for maintaining the confidentiality of
          your account login credentials.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          3. Privacy and Data Collection
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          We collect and process your personal data in accordance with this
          Policy. By signing up, you consent to our data collection and use
          practices.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          4. User Responsibilities
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          You agree not to use our services for any unlawful, fraudulent, or
          malicious purposes.
          <br />
          You agree not to impersonate any other person or entity.
          <br />
          You agree not to engage in any activity that disrupts or interferes
          with the proper functioning of our website or services.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          5. User Content
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          By submitting content to our website, you grant us a non-exclusive,
          worldwide, royalty-free license to use, reproduce, and distribute the
          content.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          6. Termination
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          We reserve the right to suspend or terminate your account at our sole
          discretion if you violate these terms and conditions.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          7. Disclaimer
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          The content provided on our website is for informational purposes only
          and should not be considered as professional advice. We do not
          guarantee the accuracy, completeness, or reliability of any content.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          8. Limitation of Liability
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          We are not liable for any direct, indirect, incidental, special, or
          consequential damages resulting from your use of our services.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          9. Changes to Terms
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          We may update these terms and conditions from time to time, and the
          revised version will be effective immediately. You are responsible for
          checking for updates.
        </p>

        <p className='mt-5 font-semibold text-base text-left text-white'>
          10. Contact Information
        </p>
        <p className='ml-1 pt-2 pb-3 font-light text-base text-justify text-white'>
          If you have any questions or concerns about these terms and
          conditions, please contact us at <span className="font-bold">admin@phoenixsportszambia.com</span>
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default TermsAndConditions
