import React from "react"
import Services from "./services"

function AboutHome() {
  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-20'>
      <p className='text-4xl text-left font-bold text-white uppercase py-10  lg:text-5xl'>
        About <span className='text-orange-500'>us</span>
      </p>
      <hr className='w-2/12 h-2 bg-gradient-to-r from-red-700 to-orange-500 border-none' />

      <div className='flex flex-col  py-10 items-center justify-between lg:flex-row w-full'>
        <p className='font-extralight text-base text-justify text-white lg:w-7/12'>
          <span className='font-bold'>Phoenix Sports Academy</span> is for children who wish to be champions of our
          country Zambia and also those who wish to conquer in both Africa and the World. We wish to discover, nurture
          and develop young talent across the country. Our vision is to become a sports academy and sports school whose
          focus goes beyond the track and playing field but focuses on an athlete centred approach which touches every
          area of life in order to get and make the best out of our athletes. Our mission is to instil values and skills
          in our athletes as well as give them an environment that will create stability and progress in their careers.
        </p>

        <div className='w-full pt-10 lg:py-0 lg:w-4/12'>
          <Services />
        </div>
      </div>
    </div>
  )
}

export default AboutHome
