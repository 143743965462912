import React, { useState, useEffect } from "react"
import { getEvents } from "../../firebase"
import Loader from "../loader"
import { CalendarOutlined, ClockCircleOutlined, DollarOutlined, PushpinOutlined } from "@ant-design/icons"

function Events({ showMessage }) {
  const [eventItems, setEventItems] = useState("")
  const [loading, setLoading] = useState(false)

  const getAllEvents = async () => {
    setLoading(true)
    try {
      const eventData = await getEvents()
      setEventItems(eventData)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllEvents()
  }, [])

  const handleWhatsAppClick = (title) => {
    const phoneNumber = "+260964187555"
    const text = encodeURIComponent(`Hi! I wanted to register for ${title}`)

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${text}`
    window.open(whatsappUrl, "_blank")
  }

  return (
    <div>
      {loading && <Loader />}
      {eventItems.length > 0 && (
        <div className='px-[4%] lg:px-[8%] mx-auto bg-slate-800 py-20'>
          <p className='text-4xl text-center text-white font-bold uppercase py-10  lg:text-5xl '>
            Our upcoming <span className='text-orange-500'>events,</span>
            <br />
            Register <span className='text-orange-500'> Today</span>
          </p>
          <hr className='w-2/12 h-2 mx-auto bg-gradient-to-r from-red-700 to-orange-500 border-none' />

          <div className='flex flex-col justify-around gap-10 py-20  lg:flex-row text-center'>
            {eventItems.map((event, index) => (
              <div key={index} className='flex flex-col gap-5 px-5 py-10 rounded-md bg-slate-900  lg:w-1/3'>
                <p className='text-2xl text-left font-bold uppercase text-orange-500'>{event.title}</p>

                <p className='text-base text-justify font-light text-white my-3'>{event.description}</p>

                <div className='flex flex-col gap-3 pb-3'>
                  <div className='flex gap-x-5'>
                    <CalendarOutlined className='text-orange-500' />
                    <p className='text-base font-extralight text-white'>{event.date}</p>
                  </div>

                  <div className='flex gap-x-5'>
                    <ClockCircleOutlined className='text-orange-500' />
                    <p className='text-base font-extralight text-white'>{event.time} Hrs</p>
                  </div>

                  <div className='flex gap-x-5 w-fit'>
                    <PushpinOutlined className='text-orange-500' />
                    <p className='text-base font-extralight text-white'>{event.location}</p>
                  </div>

                  <div className='flex gap-x-5 w-fit'>
                    <DollarOutlined className='text-orange-500' />
                    <p className='text-base font-extralight text-white'>K{event.price}</p>
                  </div>
                </div>

                <button
                  onClick={() => handleWhatsAppClick(event.title)}
                  className='text-white text-sm bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
                >
                  Register
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {eventItems.length < 1 && showMessage && (
        <div className='h-[70vh] px-[4%] lg:px-[8%] mx-auto flex items-center justify-center bg-slate-800 py-20'>
          <p className='text-white'>No events currently</p>
        </div>
      )}
    </div>
  )
}

export default Events
