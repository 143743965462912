import React from "react"
import cardImage1 from "../../img/banner8.jpeg"
import cardImage2 from "../../img/banner3.jpeg"
import cardImage3 from "../../img/banner6.jpeg"

function ProgramCategories({ signUp }) {
  return (
    <div className="w-11/12 lg:w-10/12 mx-auto py-10">
      <h1 className="text-3xl lg:text-5xl text-left font-bold text-orange-500 uppercase pb-10">
        Training Programs
      </h1>

      <div className="flex flex-wrap items-center">
        <div className="lg:w-1/3 md:w-full p-2">
          <div className="bg-white rounded overflow-hidden shadow-md">
            <img
              src={cardImage1}
              alt="Card 1"
              className="w-full h-96 object-cover"
            />
            <div className="p-4 flex flex-col justify-center">
              <p className="text-2xl font-bold uppercase mb-2 text-center text-orange-500">
                Track 4 Tots: 4-9 Years
              </p>

              <p className="text-black text-center text-base py-5">
                Training schedule is every Saturday
              </p>

              <p className="text-base text-justify text-black">
            Introduce your young champions to the world of sports with our Track
            4 Tots program. Designed for kids aged 4 to 9, this fun and engaging
            program offers weekly training sessions every Saturday. Our
            experienced coaches focus on building essential athletic skills,
            nurturing a love for physical activity, and fostering teamwork in a
            supportive and inclusive environment. Join us for an exciting
            journey of growth and discovery!
          </p>

          <button
            onClick={() => {
              signUp("block")
            }}
            className="text-white text-sm bg-orange-500 py-2 px-8 my-5 rounded"
          >
            Sign Up
          </button>
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 md:w-full p-2">
          <div className="bg-white rounded-lg overflow-hidden shadow-md">
            <img
              src={cardImage2}
              alt="Card 2"
              className="w-full h-96 object-cover"
            />
            <div className="p-4 flex flex-col justify-center">
              <p className="text-2xl font-bold uppercase mb-2 text-center text-orange-500">
                Under 13: 10-13 Years
              </p>

              <p className="text-black text-center text-base py-5">
                Training schedule is 2 times a week
              </p>

              <p className="text-base text-justify text-black">
            At our sports academy, we are committed to helping your child excel.
            Our Under 13 program, designed for ages 10 to 13, offers tailored
            training schedules that work around your child's school timetable.
            During term time, we provide comprehensive training twice a week,
            ensuring your child receives expert guidance to develop their
            athletic abilities. Join us and watch your young athlete thrive!
          </p>

          <button
            onClick={() => {
              signUp("block")
            }}
            className="text-white text-sm bg-orange-500 py-2 px-8 my-5 rounded"
          >
            Sign Up
          </button>
              
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 md:w-full p-2">
          <div className="bg-white rounded-lg overflow-hidden shadow-md">
            <img
              src={cardImage3}
              alt="Card 3"
              className="w-full h-96 object-cover"
            />
            <div className="p-4 flex flex-col justify-center">
              <p className="text-2xl font-bold uppercase mb-2 text-center text-orange-500">
                Youth: 14-17 Years
              </p>

              <p className="text-black text-center text-base py-5">
                Training schedule is 3 times a week
              </p>

              <p className="text-base text-justify text-black">
            For older athletes aged 14 to 17, our Youth program provides a
            dynamic and challenging training environment. During term time, our
            dedicated coaches lead sessions three times a week, helping athletes
            refine their skills, improve endurance, and achieve their goals.
            During school holidays, our commitment to excellence continues with
            four weekly training sessions. Join our Youth program and experience
            the journey to athletic success!
          </p>

          <button
            onClick={() => {
              signUp("block")
            }}
            className="text-white text-sm bg-orange-500 py-2 px-8 my-5 rounded"
          >
            Sign Up
          </button>
            </div>
          </div>
        </div>
      </div>

      <p className="text-white font-extralight text-base text-center mt-5">
        The academy is open to boys and girls of ages 4-17 years. All schedules
        are worked out with you and our athlete support team based on your
        school timetable
      </p>
    </div>
  )
}

export default ProgramCategories
