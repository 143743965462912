import React from "react"
import shopBanner from "../../img/shopBanner.png"
import Navtab from "../navtab"

function ShopBanner() {
  return (
    <div className="bg-gradient-to-r bg-zinc-900">
      <div className="w-4/5 mx-auto">
        <Navtab />
      </div>

      <div className="w-11/12 pt-20 mx-auto flex flex-col gap-10 items-center justify-between lg:flex-row lg:h-[60vh] lg:pt-0 lg:gap-0 lg:w-4/5">
        <p className="text-4xl font-bold text-white text-center lg:w-3/5 lg:text-6xl lg:text-left">
        Elevate Your Game. Shop the <span className="text-orange-500">Winning Gear</span>
        </p>

        <img className="h-full" src={shopBanner} alt="shopBannerImg" />
      </div>
    </div>
  )
}

export default ShopBanner
