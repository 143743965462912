import React from "react";
import Button from "../button";
import shoe from "../../img/shopBanner.png";

function ShopSection() {
  return (
    <div className="px-[7.5%] mx-auto flex flex-col items-center gap-20 pt-20  lg:flex-row bg-slate-800">
      <div className="flex flex-col gap-10 items-center lg:items-start lg:w-3/5">
        <p className="text-3xl lg:text-5xl text-center lg:text-left font-bold text-white capitalize">
          Performance Meets <span className="text-orange-500">Style.</span>
          <br/>Phoenix<span className="text-orange-500"> Sports Shop</span>
        </p>

        <Button buttonText={"Shop now"} navRoute={"/shop"} />
      </div>

      <div className="lg:w-2/5">
        <img className="w-3/5 mx-auto object-cover" src={shoe} alt="show" />
      </div>
    </div>
  );
}

export default ShopSection;
