import React from "react"
import programImage1 from "../../img/banner8.jpeg"
import programImage2 from "../../img/banner3.jpeg"
import programImage3 from "../../img/banner6.jpeg"
import programImage4 from "../../img/banner4.jpeg"

function Programs({ signUp }) {
  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-20'>
      <p className='text-4xl text-left font-black text-white uppercase py-10 lg:text-6xl'>
        Building <span className='text-orange-500'>Champions</span> <br />
        Specialized <span className='text-orange-500'>Training Programs</span>
      </p>
      <hr className='w-2/12 h-2 bg-gradient-to-r from-red-700 to-orange-500 border-none' />

      <div className='flex flex-col gap-20 py-20'>
        <div className='flex flex-col gap-20 items-center lg:flex-row'>
          <div className='flex flex-col gap-10  lg:w-2/4'>
            <h1 className='text-2xl lg:text-3xl text-left font-bold text-white uppercase'>
              Track 4 Tots: 4-9 Years
            </h1>

            <p className='font-extralight text-base text-left text-white'>
              Introduce your young champions to the world of sports with our
              Track 4 Tots program. Designed for kids aged 4 to 9, this fun and
              engaging program offers weekly training sessions every Saturday.
              Our experienced coaches focus on building essential athletic
              skills, nurturing a love for physical activity, and fostering
              teamwork in a supportive and inclusive environment. Join us for an
              exciting journey of growth and discovery!
            </p>

            <button
              onClick={() => {
                signUp("block")
              }}
              className='w-fit text-white text-base bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
            >
              Sign Up
            </button>
          </div>

          <div className=' lg:w-2/4'>
            <img
              className='object-cover rounded-2xl'
              src={programImage1}
              alt='program1'
            />
          </div>
        </div>

        <div className='flex flex-col gap-20 items-center  lg:flex-row'>
          <div className='lg:w-2/4'>
            <img
              className='object-cover rounded-2xl'
              src={programImage2}
              alt='program1'
            />
          </div>

          <div className='flex flex-col gap-10 lg:w-2/4'>
            <h1 className='text-2xl lg:text-3xl text-left font-bold text-white uppercase'>
              Under 13: 10-13 Years
            </h1>

            <p className='font-extralight text-base text-left text-white'>
              At our sports academy, we are committed to helping your child
              excel. Our Under 13 program, designed for ages 10 to 13, offers
              tailored training schedules that work around your child's school
              timetable. During term time, we provide comprehensive training
              twice a week, ensuring your child receives expert guidance to
              develop their athletic abilities. Join us and watch your young
              athlete thrive!
            </p>

            <button
              onClick={() => {
                signUp("block")
              }}
              className='w-fit text-white text-base bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
            >
              Sign Up
            </button>
          </div>
        </div>

        <div className='flex flex-col gap-20 items-center  lg:flex-row'>
          <div className='flex flex-col gap-10  lg:w-2/4'>
            <h1 className='text-2xl lg:text-3xl  text-left font-bold text-white uppercase'>
              Youth: 14-17 Years
            </h1>

            <p className='font-extralight text-base text-left text-white'>
              For older athletes aged 14 to 17, our Youth program provides a
              dynamic and challenging training environment. During term time,
              our dedicated coaches lead sessions three times a week, helping
              athletes refine their skills, improve endurance, and achieve their
              goals. During school holidays, our commitment to excellence
              continues with four weekly training sessions. Join our Youth
              program and experience the journey to athletic success!
            </p>

            <button
              onClick={() => {
                signUp("block")
              }}
              className='w-fit text-white text-base bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
            >
              Sign Up
            </button>
          </div>

          <div className='lg:w-2/4'>
            <img
              className='object-cover  rounded-2xl'
              src={programImage3}
              alt='program1'
            />
          </div>
        </div>

        <div className='flex flex-col gap-20 items-center  lg:flex-row'>
          <div className='lg:w-2/4'>
            <img
              className='object-cover rounded-2xl'
              src={programImage4}
              alt='program4'
            />
          </div>

          <div className='flex flex-col gap-10 lg:w-2/4'>
            <h1 className='text-2xl lg:text-3xl text-left font-bold text-white uppercase'>
              Football
            </h1>

            <p className='font-extralight text-base text-left text-white'>
              Not all champions are born, many of them are built. We are here to
              nurture and develop young talent in football for children aged 4 -
              17 years of age. Dare to dream! Take that first step by signing up
            </p>

            <button
              onClick={() => {
                signUp("block")
              }}
              className='w-fit text-white text-base bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Programs
