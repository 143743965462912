import React from "react"
import {
  PhoneOutlined,
  MailOutlined,
  PushpinOutlined,
  FacebookOutlined,
} from "@ant-design/icons"

function Contact() {
  const handleSubmit = (event) => {
    event.preventDefault()

    const recipientEmail = "Info@phoenixsportszambia.com"
    const subject = `Enquiry | ${event.target.name.value}`
    const phoneNumber = event.target.phone.value
    const message = event.target.message.value

    const mailtoLink = `mailto:${recipientEmail}?subject=${subject}&body=${phoneNumber}%0A%0A${message}`

    window.location.href = mailtoLink
  }

  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-20'>
      <p className='text-4xl lg:text-5xl text-left font-bold text-white py-10 uppercase'>
        Have <span className='text-orange-500'>Questions?</span> <br />
        Contact <span className='text-orange-500'>Us 👋</span>
      </p>

      <div className='flex lg:flex-row flex-col'>
        <div className='lg:w-6/12 w-12/12 py-20 bg-slate-800 px-5'>
          <p className='text-2xl lg:text-3xl text-left font-bold text-orange-500 uppercase'>
            Contact Information
          </p>

          <p className='font-light text-base text-white pt-2'>
            Write us a message, give us a call or follow us on social media
          </p>

          <ul className='flex flex-col gap-y-5 mt-10'>
            <li className='flex items-center gap-x-5 text-white'>
              <PhoneOutlined className='text-orange-500' />
              +260 964187555, +260 0761680464
            </li>

            <li className='flex items-center gap-x-5 text-white'>
              <MailOutlined className='text-orange-500' />{" "}
              Info@phoenixsportszambia.com
            </li>

            <li className='flex items-center gap-x-5 text-white'>
              <PushpinOutlined className='text-orange-500' /> Lusaka
            </li>

            <li className='text-white'>
              <a
                href='https://www.facebook.com/phoenixsportszm'
                className='flex items-center gap-x-5 '
              >
                <FacebookOutlined className='text-orange-500' /> Facebook
              </a>
            </li>
          </ul>
        </div>

        <form
          onSubmit={handleSubmit}
          className='lg:w-6/12 w-12/12 flex flex-col gap-y-5 py-20 bg-white px-5'
        >
          <div className='flex flex-col w-4/4'>
            <label htmlFor='name' className='text-xs font-semibold'>
              Full name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              className='bg-zinc-100 h-10 p-2 rounded'
            />
          </div>

          <div className='flex flex-col w-4/4'>
            <label htmlFor='Phone' className='text-xs font-semibold'>
              Phone
            </label>
            <input
              type='number'
              id='phone'
              name='phone'
              className='bg-zinc-100 h-10 p-2 rounded'
            />
          </div>

          <div className='flex flex-col w-4/4'>
            <label htmlFor='message' className='text-xs font-semibold'>
              Message
            </label>
            <textarea
              id='message'
              name='message'
              className='bg-zinc-100 p-2 rounded h-20'
            />
          </div>

          <button
            type='submit'
            className='w-fit mt-5 text-white text-sm bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact
