import React, { useState, useEffect } from "react"
import { getFile } from "../../firebase"

function GetFiles({ category, subCategory, userId }) {
  const [nutrition, setNutrition] = useState("")

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const userNutrition = await getFile(category, subCategory, userId)
      setNutrition(Object.values(userNutrition))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='p-2 mb-5'>
      <p className='font-bold text-orange-500 bg-orange-100 px-1 py-2 capitalize'>{subCategory}</p>

      {nutrition && (
        <div className='py-3 px-2 flex flex-wrap gap-2 bg-gray-50 p-3'>
          {nutrition.map((item) => {
            return (
              <div className='w-fit border rounded bg-gray-200 px-3 py-1 flex-row'>
                <p className=''>
                  <a className='text-blue-600' href={item.file}>
                    {item.name}
                  </a>
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default GetFiles
