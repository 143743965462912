import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { logIn } from "../firebase"
import Loader from "../components/loader"
import logo from "../img/logo.png"

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const onLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      let user = await logIn(email, password)
      setLoading(false)
      navigate("/athlete", { state: { userId: user.user.uid } })
    } catch (error) {
      setErrorMessage("Invalid credentials")
      setLoading(false)
    }
  }

  return (
    <>
      <main>
        <section className='bg-gray-100 h-screen flex items-center justify-center'>
          <div className='bg-white p-8 rounded shadow-lg w-96'>
          <img src={logo} alt='logo' className='w-10 py-1 mx-auto' />
            <p
              className='text-base text-center font-semibold lg:block
                         hover:text-orange-500
                         bg-gradient-to-b from-red-700 to-orange-500 bg-clip-text text-transparent'
            >
              PHOENIX SPORTS ACADEMY
            </p>

            <p className='text-3xl font-semibold text-orange-500 text-center border-b border-b-orange-500 mt-10 mb-6'>
              ATHLETE HUB LOGIN
            </p>
            <form>
              <div className='mb-4'>
                <label
                  htmlFor='email-address'
                  className='block text-xs font-medium text-gray-700'
                >
                  Email address
                </label>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  required
                  placeholder='Email address'
                  onChange={(e) => setEmail(e.target.value)}
                  className='mt-1 p-2 border rounded w-full focus:ring-orange-500 focus:border-orange-500'
                />
              </div>

              <div className='mb-4'>
                <label
                  htmlFor='password'
                  className='block text-xs font-medium text-gray-700'
                >
                  Password
                </label>
                <input
                  id='password'
                  name='password'
                  type='password'
                  required
                  placeholder='Password'
                  onChange={(e) => setPassword(e.target.value)}
                  className='mt-1 p-2 border rounded w-full focus:ring-orange-500 focus:border-orange-500'
                />
              </div>

              <div>
                <p className='mt-4 text-sm text-red-500 text-center'>
                  {errorMessage}
                </p>

                {loading && <Loader />}

                <button
                  type='submit'
                  onClick={onLogin}
                  className='mt-4 w-full py-2 bg-orange-500 text-white rounded hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-orange-500'
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  )
}

export default Login
