import React from "react"
import shopItem from "../../img/shoe.png"

function ShopItem(props) {
  return (
    <div className="w-full lg:w-3/12">
      <div className="w-full bg-orange-50 rounded-t-md">
        <img className="w-4/5 mx-auto" src={shopItem} alt="shopItem" />
      </div>

      <div className="py-1">
        <div className="flex justify-between items-end">
          <p className="text-base font-medium">{props.items.item}</p>
          <p className="text-2xl font-black text-orange-500 flex gap-1">
            <span className="text-xs self-center">K </span> {props.items.price}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShopItem
