import React, { useState } from "react"
import emailjs from "@emailjs/browser"
import Button from "../button"
import { CloseCircleOutlined } from "@ant-design/icons"
import Loader from "../loader"
import { Link } from "react-router-dom"

function SignUp({ signUp }) {
  const [name, setName] = useState("")
  const [age, setAge] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [program, setProgram] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    var templateParams = {
      name: name,
      age: age,
      email: email,
      phone: phone,
      program: program,
    }
    setLoading(true)
    try {
      const response = await emailjs.send(
        "service_vjzq78e",
        "template_rxndr88",
        templateParams,
        "GOt38AnMGYeGsBpE-"
      )
      if (response.status === 200) {
        setLoading(false)
        signUp("none")
        alert(
          `Sign up was successful, kindly check your email ${email} for next steps`
        )
        console.log("Send email: ", response.status, response.text)
      } else {
        setLoading(false)
        alert(
          "Error signing up, kindly contact admin on 0964187555 or 0761680464"
        )
      }
    } catch (error) {
      setLoading(false)
      console.log("error: ", error)
      alert(
        "Error signing up, kindly contact admin on 0964187555 or 0761680464"
      )
    }
  }

  return (
    <div className='py-20 bg-black bg-opacity-70 fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full'>
      <div className='w-11/12 lg:w-4/5 mx-auto flex rounded bg-white'>
        <div className="w-2/5 hidden bg-[url('/src/img/banner3.jpeg')] bg-cover bg-center rounded-l lg:block"></div>
        {loading && <Loader />}
        <div className='w-11/12 lg:w-3/5 mx-auto'>
          <div className='flex justify-end'>
            <p
              onClick={() => {
                signUp("none")
              }}
              className='font-bold text-xl text-right py-3 px-5 cursor-pointer mb-3'
            >
              <CloseCircleOutlined />
            </p>
          </div>

          <p className='w-11/12 lg:w-4/5 mx-auto uppercase font-bold text-smz lg:text-xl text-orange-500'>
            Sign Up To Phoenix Sports Academy
          </p>

          <form
            onSubmit={handleSubmit}
            className='w-11/12 gap-y-2 lg:w-4/5 mx-auto py-10 flex flex-wrap lg:gap-y-4'
          >
            <div className='w-full flex flex-col gap-y-2 lg:flex lg:flex-row lg:gap-x-10 lg:gap-y-0'>
              <label className='w-full lg:w-2/4 flex flex-col font-semibold text-xs'>
                Full Name
                <input
                  type='text'
                  className='bg-zinc-100 h-10 p-2  rounded'
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </label>

              <label className='w-full lg:w-2/4 flex flex-col font-semibold text-xs'>
                Age
                <input
                  type='number'
                  className='bg-zinc-100 h-10 p-2  rounded'
                  value={age}
                  min='4'
                  max='17'
                  required
                  onChange={(e) => setAge(e.target.value)}
                />
              </label>
            </div>

            <div className='w-full flex flex-col gap-y-2 lg:flex lg:flex-row lg:gap-x-10 lg:gap-y-0'>
              <label className='w-full lg:w-2/4 flex flex-col font-semibold text-xs'>
                Email
                <input
                  type='email'
                  className='bg-zinc-100 h-10 p-2  rounded'
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>

              <label className='w-full lg:w-2/4 flex flex-col font-semibold text-xs'>
                Phone number
                <input
                  type='text'
                  className='bg-zinc-100 h-10 p-2  rounded'
                  value={phone}
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
              </label>
            </div>

            <div className='w-full flex flex-col gap-y-2 lg:flex lg:flex-row lg:gap-x-10 lg:gap-y-0'>
              <label className='w-full flex flex-col font-semibold text-xs'>
                Program
                <select
                  className='bg-zinc-100 h-10 p-2  rounded'
                  value={program}
                  onChange={(e) => setProgram(e.target.value)}
                >
                  <option value=''>Select program</option>
                  <option value='Track 4 Tots: 4-9 Years'>
                    Track 4 Tots: 4-9 Years
                  </option>
                  <option value='Under 13: 10-13 Years'>
                    Under 13: 10-13 Years
                  </option>
                  <option value='Youth: 14-17 Years'>Youth: 14-17 Years</option>
                  <option value='Football'>Football</option>
                </select>
              </label>
            </div>

            <div className='flex flex-col gap-7'>
              <label className=' text-xs font-medium'>
                By signing up you are accepting the following{" "}
                <span className='text-orange-500'>
                  {" "}
                  <Link to='/termsandconditions'>Terms And Conditions</Link>
                </span>
              </label>

              <Button type='submit' buttonText={"Sign Up"} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUp
