import React, { useState } from "react"
import ProgramBanner from '../components/programs/programBanner'
import ProgramAbout from '../components/programs/programAbout'
import ProgramCategories from '../components/programs/programCategories'
import Footer from "../components/footer"
import SignUp from "../components/home/signUp"


function Programs() {
  const [signUp, setSignUp] = useState("none")

  const showSignUp = (signUpDisplay) => {
    setSignUp(signUpDisplay)
  }
  return (
    <div>
      <ProgramBanner />
      <ProgramAbout />
      <div style={{backgroundColor: "red", display: signUp}}>
        <SignUp signUp={showSignUp} />
      </div>
      <ProgramCategories signUp={showSignUp} />
      <Footer />
    </div>
  )
}

export default Programs