import React from "react"


function Services() {
  return (
    <div className='w-full mx-auto flex flex-col gap-y-5 '>
      <div className='flex flex-row gap-x-2 justify-between'>
        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>
            sentiment_satisfied
          </i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Optimism
          </p>
        </div>

        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>thumb_up</i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Courage
          </p>
        </div>

        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>star</i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Leadership
          </p>
        </div>

        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>favorite</i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Passion
          </p>
        </div>
      </div>

      <div className='flex flex-row gap-x-2 justify-between'>
        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>wb_sunny</i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Resilience
          </p>
        </div>

        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>
            directions_run
          </i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Determination
          </p>
        </div>

        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>
            verified_user
          </i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Trust
          </p>
        </div>

        <div className='flex flex-col items-center w-1/5'>
          <i className='material-icons text-orange-500 text-4xl'>equalizer</i>

          <p className='text-sm font-medium uppercase font-light text-white text-center pt-3'>
            Balance
          </p>
        </div>
      </div>
    </div>
  )
}

export default Services
