import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/home"
import About from "./pages/about"
import Shop from "./pages/shop"
import Programs from "./pages/programs"
import Login from "./pages/login"
import Athlete from "./pages/athlete"
import TermsAndConditions from "./pages/termsAndConditions"
import Event from "./pages/event"

function App() {
  return (
    <div className='App font-Rajdhani bg-zinc-900'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/programs' element={<Programs />} />
          <Route path='/login' element={<Login />} />
          <Route path='/athlete' element={<Athlete />} />
          <Route path='/events' element={<Event />} />
          <Route path='/termsandconditions' element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
