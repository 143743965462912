import React from 'react'
import { useNavigate } from "react-router-dom";

function Button(props) {
  const navigate = useNavigate();
  
  return (
    <div>
        <button onClick={() => navigate(props.navRoute)} className="text-white text-sm bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded">
            {props.buttonText}
        </button>
    </div>
  )
}

export default Button