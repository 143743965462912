import React, { useState } from "react"
import Banner from "../components/home/banner"
import Lifestyle from "../components/home/lifestyle"
import Contact from "../components/home/contact"
import Events from "../components/home/events"
import Footer from "../components/footer"
import Programs from "../components/home/programs"
import ShopSection from "../components/home/shopSection"
import Testimony from "../components/home/testimony"
import SignUp from "../components/home/signUp"
import AboutHome from "../components/home/aboutHome"

function Home() {
  const [signUp, setSignUp] = useState("none")

  const showSignUp = (signUpDisplay) => {
    setSignUp(signUpDisplay)
  }

  return (
    <div>
      <Banner signUp={showSignUp} />

      <div id="About">
        <AboutHome />
      </div>

      <div style={{backgroundColor: "red", display: signUp}}>
        <SignUp signUp={showSignUp} />
      </div>

      <div id="programs">
        <Programs signUp={showSignUp}  />
      </div>

      <ShopSection />
      <Lifestyle />

      <div id="events">
        <Events />
      </div>

      <Testimony />

      <div id="contact">
        <Contact />
      </div>

      <Footer />
    </div>
  )
}

export default Home
