import React, { useState, useEffect } from "react"
import ShopBanner from "../components/shop/shopBanner"
import ShopItem from "../components/shop/shopItem"
import { getShopItems } from "../firebase"

function Shop() {
  const [shopItems1, setShopItems1] = useState("")
  const [shopItems2, setShopItems2] = useState("")
  const [shopItems3, setShopItems3] = useState("")

  const getAllEvents = async () => {
    try {
      const shopData = await getShopItems()

      const filteredItems1 = shopData.filter((item) => item.category === "activewear")
      const filteredItems2 = shopData.filter((item) => item.category === "training accessories")
      const filteredItems3 = shopData.filter((item) => item.category === "bars, drinks and snacks")

      setShopItems1(filteredItems1)
      setShopItems2(filteredItems2)
      setShopItems3(filteredItems3)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllEvents()
  }, [])

  return (
    <div className='bg-white'>
      <ShopBanner />

      <div className='w-11/12 lg:w-10/12 mx-auto py-10'>
        <div className='py-5'>
          <p className='capitalize font-semibold text-2xl text-orange-500 underline'>activewear</p>
        </div>

        {shopItems1.length > 0 ? (
          <div className='py-10 flex flex-wrap gap-10'>
            {shopItems1.map((item, index) => (
              <ShopItem items={item} />
            ))}
          </div>
        ) : (
          <p>No items currently available</p>
        )}

        <div className='py-5'>
          <p className='capitalize font-semibold text-2xl text-orange-500 underline'>training accessories</p>
        </div>

        {shopItems2.length > 0 ? (
          <div className='py-10 flex flex-wrap gap-10'>
            {shopItems2.map((item, index) => (
              <ShopItem items={item} />
            ))}
          </div>
        ) : (
          <p>No items currently available</p>
        )}

        <div className='py-5'>
          <p className='capitalize font-semibold text-2xl text-orange-500 underline'>bars, drinks and snacks</p>
        </div>

        {shopItems3.length > 0 ? (
          <div className='py-10 flex flex-wrap gap-10'>
            {shopItems3.map((item, index) => (
              <ShopItem items={item} />
            ))}
          </div>
        ) : (
          <p>No items currently available</p>
        )}
      </div>
    </div>
  )
}

export default Shop
