import React from "react"
import Services from "../components/home/services"
import testimonyImage1 from "../img/testimonyImage2.jpg"
import Footer from "../components/footer"
import AboutBanner from "../components/about/aboutBanner"

function About() {
  return (
    <div className=''>
      <AboutBanner />

      <div className='w-11/12 lg:w-10/12 py-10 mx-auto'>
        <h1 className='text-3xl lg:text-5xl text-left font-bold text-orange-500 uppercase py-10'>Overview</h1>

        <div className='flex flex-col justify-between lg:flex-row'>
          <p className='font-extralight text-base text-justify text-white lg:w-7/12'>
            <span className='font-bold'>Phoenix Sports Academy</span> is for children who wish to be champions of our
            country Zambia and also those who wish to conquer in both Africa and the World. We wish to discover, nurture
            and develop young talent across the country. Our vision is to become a sports academy and sports school
            whose focus goes beyond the track and playing field but focuses on an athlete centred approach which touches
            every area of life in order to get and make the best out of our athletes. Our mission is to instil values
            and skills in our athletes as well as give them an environment that will create stability and progress in
            their careers.
          </p>

          <div className='pt-10 lg:pt-0 lg:w-4/12'>
            <Services />
          </div>
        </div>
      </div>

      <div className='w-11/12 lg:w-10/12 lg:w-4/5 mx-auto'>
        <h1 className='text-3xl lg:text-5xl text-left font-bold text-orange-500 uppercase py-10'>The Dream</h1>

        <p className='font-extralight text-base text-justify text-white pb-10 '>
          Phoenix Sports aims to have a state of the art academy offering services from coaching, health, nutrition ,
          strengthening and conditioning and all the elements that come in to create a high performance athlete.
        </p>

        <h1 className='text-3xl lg:text-5xl text-left font-bold text-orange-500 uppercase py-10'>Competing</h1>

        <p className='font-extralight text-base text-justify text-white pb-10 '>
          We will train our athletes in line with the Zambian and World Athletics calendar to ensure they are
          progressing correctly and can align their goals and training plans well enough to get them into competitions .
          We wish to have many of our athletes gaining spots on the national team and representing Zambia. For the
          foreign national in Zambia, they too can benefit and equally train to get on their national teams as we the
          athletics calendar is universal around the world.
          <br />
          Typically, the Zambian Calendar has competitions starting roughly at the end of December or Mid January. Our
          Academy will prepare the athletes for these competitions. Our main focus is to develop athletes and ensure
          they have steady and age appropriate progression. We will be very prudent with how many races we allow our
          athletes to participate in and when. <br />
          Our younger athletes under the Track 4 Tots program will occasionally have sports days and competitive events
          too.
        </p>

        <h1 className='text-3xl lg:text-5xl text-left font-bold text-orange-500 uppercase py-10'>Training</h1>

        <p className='font-extralight text-base text-justify text-white pb-10 '>
          Our training philosophy has 4 objectives which take time and the right age to accomplish. We believe if our
          athletes go through each stage of the philosophy, they can grow and develop and excel in the sport. <br />
          <br />
          1. Learning to train <br />
          2. Learning to compete <br />
          3. Learning to compete to win <br />
          4. Learning to compete to live <br />
        </p>

        <div className=''>
          <h1 className='text-3xl lg:text-5xl text-center font-bold text-orange-500 uppercase py-10'>The Team</h1>

          <div className='py-10 flex flex-wrap gap-y-10 text-center'>
            <div className='flex flex-col items-end w-2/4 lg:w-1/4 items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Leah Mpondela</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>Chairperson</p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4 items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Velepi Chipeta</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>Treasurer</p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4  items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Fungayi Chituta</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>General Secretary</p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4  items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Douglas Kalembo</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>Technical Director</p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4  items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Chipampe Mpondela</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>
                Head of Lifestyle and Wellness
              </p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4  items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Douglas Kalembo</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>Technical Director</p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4  items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Joseph Daka</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>
                CTrack 4 Tots Head Coach
              </p>
            </div>

            <div className='flex flex-col items-end w-2/4  lg:w-1/4  items-center justify-center '>
              <img className='w-[50px] h-[50px] rounded-full' src={testimonyImage1} alt='testimonyImage2' />

              <p className='text-white font-light text-xs lg:text-base mt-5'>Kezzias Nyendwa</p>
              <p className='text-orange-500 uppercase font-semibold text-xs lg:text-base mt-1'>Youth Head Coach</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default About
