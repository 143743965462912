import React from "react";


function Footer() {
  return (
    <div className="px-[8%] pb-20 pt-5 mt-20 gap-7 mx-auto flex flex-col justify-between border-t border-orange-900   lg:items-center lg:flex-row lg:gap-0">
      <div className="">
        <ul className="flex justify-center text-sm text-white font-medium uppercase gap-2  lg:flex-row lg:flex lg:space-x-7">
          <li className="hover:text-orange-500">Home</li>

          <li className="hover:text-orange-500">About</li>

          <li className="hover:text-orange-500">Programs</li>

          <li className="hover:text-orange-500">Events</li>

          <li className="hover:text-orange-500">Contact</li>
        </ul>
      </div>


      <div className="items-center text-center lg:w-2/4 lg:text-right">
        <p className="text-sm text-orange-500 font-extralight">© Phoenix Sports Academy. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
