import React from "react"
import Navtab from "../navtab"

function Banner({ signUp }) {
  return (
    <div
      className="h-[100vh] mx-auto pb-20
      bg-[linear-gradient(to_bottom,rgba(0,0,0,0.9),rgba(0,0,0,0.8)),url('/src/img/SignUpImage.jpg')]
      bg-cover bg-center flex flex-col"
    >
      <div className='w-11/12 lg:w-10/12 mx-auto'>
        <Navtab />
      </div>

      <div className='w-11/12 lg:w-10/12 h-full mx-auto flex flex-col justify-center gap-y-7'>
        <p
          className='
        hidden lg:block
        lg:text-6xl text-center font-black uppercase text-orange-500'
        >
          Inspired.determined.resilent
        </p>

        <p
          className='
        text-6xl
        lg:hidden text-center font-black uppercase text-orange-500'
        >
          Inspired.
          determined.
          resilient.
        </p>

        <p className='w-full font-normal text-base text-center text-white'>
          Join us on a journey of dedication and passion, where we nurture
          talent and shape tomorrows champions
        </p>

        <button
          onClick={() => {
            signUp("block")
          }}
          className='w-fit mx-auto text-white text-sm bg-gradient-to-r from-red-700 to-orange-500 py-3 px-10 rounded'
        >
          Sign Up
        </button>
      </div>
    </div>
  )
}

export default Banner
