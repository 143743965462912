import React from "react"

function ProgramAbout() {
  return (
    <div className="w-11/12 lg:w-10/12 mx-auto flex flex-col gap-10 py-10">
      <h1 className="text-3xl lg:text-5xl  text-left font-bold text-orange-500 uppercase mt-10">
        Overview
      </h1>

      <p className="font-extralight text-base text-left text-white">
        PHOENIX SPORTS Academy sprint program adapts an athlete centered
        approach. We build a team around the athletes to ensure they are looked
        after on and off the track. We are comfortable knowing that our seasoned
        and highly experienced coaches will drive our programs and support as
        well as motivate the athletes to do their best. By focusing on all the
        elements of the athlete from their personal development, mental health,
        academics , nutrition and track life. We allow our athletes to
        strengthen their intrinsic motivation element through the transferable
        skills and values such as passion, determination and resilience. The
        program starts in October and follows the universal world athletics
        calendar. With the support of our team, our athletes will firstly set
        their goals. , begin to work on achieving them and getting the extrinsic
        drive from their training partners. We offer training specific to the
        sprint events our athletes will master and we follow the Zambian
        Athletics Federation annual competition program while also adding a few
        of our own time trials to track progress. In addition to this, we will
        ensure our athletes are doing well in school and provide them with the
        tools to be able to strike the balance. We have an in-house support team
        dedicated to advise and mentor our athletes. We trust that Phoenix is
        the destination for young athletes hoping to kick start their journey on
        becoming champions.
      </p>

      <h1 className="text-3xl lg:text-5xl  text-left font-bold text-orange-500 uppercase mt-10">
        Structure
      </h1>

      <p className="font-extralight text-base text-left text-white">
        Our programs offers the best environment for young athletes to receive
        the appropriate training to start their athletics journey and develop
        well rounded athletes while still keeping the importance of balance and
        getting a good education our line of vision. Our athletes receive
        personalised training plans which encompass all the cycles of training ,
        competing and recovering.
      </p>

      <div className="text-white flex flex-col gap-3 font-extralight text-sm ">
        <p className="font-medium">
          Each athlete on our program will have access to:
        </p>

        <p className="ml-5">● An individual development plan</p>

        <p className="ml-5">● Individual development quarterly reports</p>

        <p className="ml-5">● A competition schedule</p>

        <p className="ml-5">● A nutrition plan</p>

        <p className="ml-5">● Access to physiotherapy</p>

        <p className="ml-5">● School timetable support</p>

        <p className="ml-5">● Strengthening and conditioning sessions</p>

        <p className="ml-5">
          ● Life coach access (mental conditioning, life skills, character
          development)
        </p>
      </div>
    </div>
  )
}

export default ProgramAbout
