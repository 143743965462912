import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { getDatabase, ref, get } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyCQL49hT3Qy1mzFWvfOb0sgvGB-f6VGC3w",
  authDomain: "phoenix-sports-academy.firebaseapp.com",
  databaseURL: "https://phoenix-sports-academy-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "phoenix-sports-academy",
  storageBucket: "phoenix-sports-academy.appspot.com",
  messagingSenderId: "886297723864",
  appId: "1:886297723864:web:2374a97a13d55c3137a8ad",
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const database = getDatabase()

//************************************************* User Functions *************************************************//
export const getUser = async (userId) => {
  try {
    const usersRef = ref(database, `db/users/${userId}`)
    const snapshot = await get(usersRef)

    if (snapshot.exists()) {
      const user = Object.entries(snapshot.val())
      const userArray = user.reduce((obj, [key, value]) => {
        obj[key] = value
        return obj
      }, {})

      return userArray
    } else {
      return []
    }
  } catch (error) {
    throw Error(error)
  }
}

//************************************************* File & Notes Functions *************************************************//
//Get user files
export const getFile = async (category, subCategory, userId) => {
  try {
    const nutritonRef = ref(database, `db/${category}/${subCategory}/${userId}`)
    const snapshot = await get(nutritonRef)

    if (snapshot.exists()) {
      const nutrition = Object.entries(snapshot.val())
      const nutritionArray = nutrition.reduce((obj, [key, value]) => {
        obj[key] = value
        return obj
      }, {})

      return nutritionArray
    } else {
      return []
    }
  } catch (error) {
    throw Error(error)
  }
}

// Get notes
export const getNotes = async (category, userId) => {
  try {
    const nutritonRef = ref(database, `db/${category}/notes/${userId}`)
    const snapshot = await get(nutritonRef)

    if (snapshot.exists()) {
      // Convert the snapshot to an array of users
      const nutrition = Object.entries(snapshot.val())
      const nutritionArray = nutrition.reduce((obj, [key, value]) => {
        obj[key] = value
        return obj
      }, {})

      return nutritionArray
    } else {
      return []
    }
  } catch (error) {
    throw Error(error)
  }
}

//************************************************* Shop FUNCTIONS *************************************************//
//Get shop items
export const getShopItems = async (userId) => {
  try {
    const shopRef = ref(database, `db/shop`)
    const snapshot = await get(shopRef)

    if (snapshot.exists()) {
      // Convert the snapshot to an array of users
      const shop = Object.entries(snapshot.val()).map(([Id, shopData]) => ({
        id: Id,
        ...shopData,
      }))

      return shop
    } else {
      return []
    }
  } catch (error) {
    throw Error(error)
  }
}

//************************************************* Event FUNCTIONS *************************************************//
// Get events
export const getEvents = async () => {
  try {
    const eventsRef = ref(database, `db/events`)
    const snapshot = await get(eventsRef)

    if (snapshot.exists()) {
      // Convert the snapshot to an array of users
      const event = Object.entries(snapshot.val()).map(([Id, eventData]) => ({
        id: Id,
        ...eventData,
      }))
      return event
    } else {
      return []
    }
  } catch (error) {
    throw Error(error)
  }
}

//************************************************* AUTH FUNCTIONS *************************************************//

// Log in
export const logIn = async (email, password) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password)
    return user
  } catch (error) {
    throw Error(error)
  }
}

//Check is user is signed in
export const authStatus = async () => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      return true
    } else {
      return false
    }
  })
}

export default app
