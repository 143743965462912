import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { getUser } from "../firebase"
import Loader from "../components/loader"
import GetFiles from "../components/athlete/getFiles"
import GetNotes from "../components/athlete/getNotes"

function Athlete({ route }) {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { userId } = state

  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState("")

  useEffect(() => {
    getFormData()
  }, [])

  const handleLogout = async () => {
    navigate("/")
  }

  const getFormData = async () => {
    setLoading(true)
    try {
      const userObject = await getUser(userId)
      setUser(userObject)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className='bg-white py-10'>
      {loading && <Loader />}

      <div className='w-11/12 lg:3/4 mx-auto flex flex-col gap-y-10'>
        <button
          onClick={() => handleLogout()}
          className='ml-auto w-fit rounded text-white text-sm bg-gradient-to-r from-red-700 to-orange-500 py-3 px-5 rounded'
        >
          Log Out
        </button>

        <p className='text-2xl  font-semibold text-orange-500 capitalize'>Welcome {user.name},</p>
      </div>

      <div className='border w-11/12 lg:3/4 mx-auto my-5'>
        <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
          Health And Lifestyle | Personal
        </p>

        <div className='border'>
          <GetFiles category={"Health And Lifestyle"} subCategory={"Mental Psychology"} userId={userId} />
          <GetFiles category={"Health And Lifestyle"} subCategory={"Journal And Goal Setting"} userId={userId} />
          <GetFiles category={"Health And Lifestyle"} subCategory={"Nutrition"} userId={userId} />
          <GetFiles category={"Health And Lifestyle"} subCategory={"Life Coaching And Mentorship"} userId={userId} />

          <GetNotes category={"Health And Lifestyle"} userId={userId} />
        </div>
      </div>

      <div className='border w-11/12 lg:3/4 mx-auto my-5'>
        <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
          Training Programs And Exercises | Personal
        </p>

        <div className='border'>
          <GetFiles category={"Training Programs And Exercises"} subCategory={"Training program"} userId={userId} />
          <GetFiles category={"Training Programs And Exercises"} subCategory={"Recovery"} userId={userId} />
          <GetFiles category={"Training Programs And Exercises"} subCategory={"Other"} userId={userId} />

          <GetNotes category={"Training Programs And Exercises"} userId={userId} />
        </div>
      </div>

      <div className='border w-11/12 lg:3/4 mx-auto my-5'>
        <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
          Reports | Personal
        </p>

        <div className='border'>
          <GetFiles category={"Reports"} subCategory={"Reports"} userId={userId} />
        </div>
      </div>

      <div className='border w-11/12 lg:3/4 mx-auto my-5'>
        <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
          Miscellaneous | Personal
        </p>

        <div className='border'>
          <GetFiles category={"Miscellaneous"} subCategory={"Miscellaneous"} userId={userId} />
        </div>
      </div>

      {user && (
        <div className='mt-20'>
          <div className='border w-11/12 lg:3/4 mx-auto my-5'>
            <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
              Health And Lifestyle | {user.program}
            </p>

            <div className='border'>
              <GetFiles category={"Health And Lifestyle"} subCategory={"Mental Psychology"} userId={user.program} />
              <GetFiles
                category={"Health And Lifestyle"}
                subCategory={"Journal And Goal Setting"}
                userId={user.program}
              />
              <GetFiles category={"Health And Lifestyle"} subCategory={"Nutrition"} userId={user.program} />
              <GetFiles
                category={"Health And Lifestyle"}
                subCategory={"Life Coaching And Mentorship"}
                userId={user.program}
              />
            </div>
          </div>

          <div className='border w-11/12 lg:3/4 mx-auto my-5'>
            <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
              Training Programs And Exercises | {user.program}
            </p>

            <div className='border'>
              <GetFiles
                category={"Training Programs And Exercises"}
                subCategory={"Training program"}
                userId={user.program}
              />
              <GetFiles category={"Training Programs And Exercises"} subCategory={"Recovery"} userId={user.program} />
              <GetFiles category={"Training Programs And Exercises"} subCategory={"Other"} userId={user.program} />
            </div>
          </div>

          <div className='border w-11/12 lg:3/4 mx-auto my-5'>
            <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
              Reports | {user.program}
            </p>

            <div className='border'>
              <GetFiles category={"Reports"} subCategory={"Reports"} userId={user.program} />
            </div>
          </div>

          <div className='border w-11/12 lg:3/4 mx-auto my-5'>
            <p className='py-3 px-2 text-white text-xl font-medium bg-gradient-to-r from-red-700 to-orange-500'>
              Miscellaneous | {user.program}
            </p>

            <div className='border'>
              <GetFiles category={"Miscellaneous"} subCategory={"Miscellaneous"} userId={user.program} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Athlete
