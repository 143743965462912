import React, { useState } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import logo from "../img/logo.png"
import { MenuOutlined, CloseCircleOutlined } from "@ant-design/icons"

function Navtab() {
  const [sidebar, setSidebar] = useState(true)
  const showSidebar = () => setSidebar(!sidebar)

  return (
    <div className='flex mx-auto items-center py-5 justify-between'>
      <Link to='/'>
        <div className='flex row items-center gap-x-2 hover:cursor-pointer'>
          <img className='h-[25px]' src={logo} alt='logo' />
          <p
            className='text-xl font-semibold lg:block
        hover:text-orange-500 
        bg-gradient-to-b from-red-700 to-orange-500 bg-clip-text text-transparent'
          >
            PHOENIX SPORTS ACADEMY
          </p>
        </div>
      </Link>

      <div className='flex hidden lg:flex'>
        <ul className='flex space-x-6 text-white items-center uppercase font-medium'>
          <li className='hover:text-orange-500'>
            <Link to='/'>Home</Link>
          </li>

          <li className='hover:text-orange-500'>
            <Link to='/about'>About</Link>
          </li>

          <li className='hover:text-orange-500'>
            <Link to='/programs'>Programs</Link>
          </li>

          <li className='hover:text-orange-500'>
            <Link to='/events'>Events</Link>
          </li>

          <li className='hover:text-orange-500'>
            <Link to='/shop'>Shop</Link>
          </li>

          <li className='hover:text-orange-500'>
            <HashLink to='/#contact'>Contact</HashLink>
          </li>

          <li className='hover:text-white capitalize w-fit mx-auto text-white text-sm bg-gradient-to-r from-red-700 to-orange-500 py-2 px-5 rounded'>
            <Link to='/login'>Login</Link>
          </li>
        </ul>
      </div>

      <div className='lg:hidden text-right'>
        <ul className='flex flex-col text-white uppercase'>
          {sidebar ? (
            <li className='self-end'>
              <Link onClick={showSidebar}>
              <MenuOutlined className="text-3xl" />
              </Link>
            </li>
          ) : (
            <div className='w-3/4 h-screen flex flex-col p-5 fixed top-0 right-0 bg-orange-500 text-xl transition ease-in-out delay-150'>
              <li className='pb-3 self-end'>
                <Link to='/' onClick={showSidebar}>
                <CloseCircleOutlined className="text-3xl" />
                </Link>
              </li>

              <span className='flex flex-col gap-5 text-center pt-10'>
                <li className='hover:text-orange-500'>
                  <Link to='/' onClick={showSidebar}>
                    Home
                  </Link>
                </li>

                <li className='hover:text-orange-500'>
                  <Link to='/about' onClick={showSidebar}>
                    About
                  </Link>
                </li>

                <li className='hover:text-orange-500'>
                  <Link to='/programs' onClick={showSidebar}>
                    Programs
                  </Link>
                </li>

                <li className='hover:text-orange-500'>
                  <Link to='/events' onClick={showSidebar}>
                    Events
                  </Link>
                </li>

                <li className='hover:text-orange-500'>
                  <Link to='/shop' onClick={showSidebar}>
                    Shop
                  </Link>
                </li>

                <li className='hover:text-orange-500'>
                  <HashLink to='/#contact' onClick={showSidebar}>
                    Contact
                  </HashLink>
                </li>

                <li className='hover:text-orange-500 font-bold'>
                  <Link to='/login'>Login</Link>
                </li>
              </span>
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Navtab
