import React from "react"
import logo from '../img/logo.png'

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-center bg-zinc-800 bg-opacity-70 z-50">
      <div
        className="aloader  animate-bounce
aspect-square w-32 flex justify-center items-center"
      >
        <img src={logo} alt='logo' />

      </div>
      <p className="text-2xl text-center text-white mt-3">Loading...</p>
    </div>
  )
}

export default Loader
