import React, { useState, useEffect } from "react"
import { getNotes } from "../../firebase"

function GetNotes({ category, userId }) {
  const [notes, setNotes] = useState("")

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const Notes = await getNotes(category, userId)
      setNotes(Notes.notes)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='p-2'>
      <p>
        <span className='font-bold'>Notes: </span>
        {notes}
      </p>
    </div>
  )
}

export default GetNotes
