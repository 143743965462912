import React from "react"
import Carousel from "nuka-carousel"
import testimonyImage1 from "../../img/testimonyImage1.png"
import testimonyImage2 from "../../img/patson.jpg"
import testimonyImage3 from "../../img/Suwilanji-Mpondela-Daka.jpg"
import testimonyImage4 from "../../img/rhoda.jpg"


const defaultControlsConfig = {
  nextButtonStyle: {
    display: "none",
  },
  prevButtonStyle: {
    display: "none",
  },
  pagingDotsStyle: {
    border: "solid 1px #F97316",
    borderRadius: "5px",
    margin: "0 5px",
  },
}

function Testimony() {
  return (
    <div className='w-full py-20'>
      <div className='w-11/12 lg:w-10/12 mx-auto'>
        <p className='text-4xl lg:text-5xl py-10 text-right font-bold text-white uppercase'>
          Real <span className='text-orange-500'>stories</span>, real <span className='text-orange-500'>success</span>
        </p>
      </div>

      <Carousel
        dragging={true}
        autoplay={true}
        defaultControlsConfig={defaultControlsConfig}
        wrapAround={true}
        autoplayInterval={5000}
        speed={2000}
      >
        <div className='w-10/12 mx-auto py-10'>
          <img className='w-[50px]' src={testimonyImage1} alt='testimonyImage1' />

          <p className='font-light text-justify text-white my-10'>
            At Phoenix Sports Academy, I've witnessed young children football skills soar to new heights. The precision
            coaching and cutting-edge training methods have not only honed their technique but also ignited a passion
            for the game. Phoenix is where football dreams become a thrilling reality!
          </p>

          <div className='flex flex-col items-end'>
            <img className='w-[80px] h-[80px] object-cover rounded-full' src={testimonyImage2} alt='testimonyImage2' />

            <p className='text-white font-semibold mt-3'>Patson Daka, Footballer</p>
          </div>
        </div>

        <div className='w-10/12 mx-auto py-10'>
          <img className='w-[50px]' src={testimonyImage1} alt='testimonyImage1' />

          <p className='font-light text-justify text-white my-10'>
            Phoenix Sports Academy isn't just a training academy, it's a dynamic arena where athleticism meets
            excellence. From the precision of football drills to the exhilaration of track and field, Phoenix has
            sculpted kids into athletes. If you're seeking a place where sports dreams come alive, this is it!
          </p>

          <div className='flex flex-col items-end'>
            <img className='w-[80px] h-[80px] object-cover rounded-full' src={testimonyImage3} alt='testimonyImage2' />

            <p className='text-white font-semibold mt-3'>Suwilanji Mpondela Daka, Athlete</p>
          </div>
        </div>

        <div className='w-10/12 mx-auto py-10'>
          <img className='w-[50px]' src={testimonyImage1} alt='testimonyImage1' />

          <p className='font-light text-justify text-white my-10'>
            Unleashing full athletic potential has became a reality at Phoenix Sports Academy. The tailored training,
            expert guidance, and unwavering support have transformed passion for athletics into a journey of personal
            triumphs. Phoenix is more than an academy, it's the launchpad for athletic greatness!
          </p>

          <div className='flex flex-col items-end'>
            <img className='w-[80px] h-[80px] object-cover rounded-full' src={testimonyImage4} alt='testimonyImage2' />

            <p className='text-white font-semibold mt-3'>Rhoda Njobvu, Oly</p>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default Testimony
