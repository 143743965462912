import React from "react"
import Navtab from "../components/navtab"
import Footer from "../components/footer"
import Events from "../components/home/events"

function Event() {
  return (
    <div className='bg-slate-800'>
      <div className='w-11/12 lg:w-10/12 mx-auto'>
        <Navtab />
      </div>

      <div>
        <Events showMessage={true} />
      </div>

      <Footer />
    </div>
  )
}

export default Event
