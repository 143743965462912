import React from 'react'
import Navtab from '../navtab';

function ProgramBanner() {
    return (
        <div
          className="h-[70vh] mx-auto 
          bg-[linear-gradient(to_right_bottom,rgba(0,0,0,0.8),rgba(255,152,56,0.6)),url('/src/img/banner1.jpeg')]
          bg-cover bg-center flex flex-col gap-32"
        >
          <div className="w-4/5 mx-auto">
            <Navtab />
          </div>
    
          <div className="w-4/5 mx-auto flex flex-col gap-7">
            <h1 className="
            text-4xl
            lg:text-6xl text-center font-extrabold lg:leading-[70px] text-white uppercase">
            Training <span className="text-orange-500">Programs</span> 
            </h1>
          </div>
        </div>
      );
}

export default ProgramBanner